
import { defineComponent, PropType } from 'vue';
import { orderType } from '@/data';

export default defineComponent({
    props: {
        data: {
            required: true,
            type: Object as PropType<orderType.OrderInfoChildren>
        }
    }
});
